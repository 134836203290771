import React from 'react';

const Footer = () => {
    return (
        <footer class="bg-gray-800 text-white py-4">
            <div class="container mx-auto w-11/12 flex justify-between items-center">
                <p class="text-sm">Copyright 2024</p>
                <ul class="flex flex-wrap items-center mt-3 text-sm text-white dark:text-gray-400 sm:mt-0">
                    <li class="mx-2">
                        <a href="#contact" class="hover:underline">Contact Us</a>
                    </li>
                    <li class="mx-2">
                        <a href="mailto:support@cloudcertificationprep.com" class="hover:underline">support@cloudcertificationprep.com</a>
                    </li>
                </ul>
            </div>
        </footer>
    )
}

export default Footer;