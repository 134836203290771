import React, { useState } from "react";
import ccpLogo from "../images/logos/ccp-logo.png";

const NavBar = () => {
  const [mobileNavState, setMobileNavState] = useState(false);

  const handleToggle = () => {
    setMobileNavState(!mobileNavState);
  };

  const navOptions = [
    {
      name: "Supported Exams",
      link: "#tests-available",
      main: false,
    },
    {
      name: "Features",
      link: "#features",
      main: false,
    },
    {
      name: "Pricing",
      link: "#pricing",
      main: false,
    },
  ];

  return (
    <nav class="bg-white-800 text-white py-3 px-6 flex justify-between items-center">
      <div class="w-24 rounded-lg">
        <a href="/">
          <img
            alt="ccp logo"
            src={ccpLogo}
            aria-hidden="true"
            class="h-12 w-24"
            fill="none"
          />
        </a>
      </div>
      <div>
        {navOptions.map((option) => {
          return option.main ? (
            <a
              href={option.link}
              class="group inline-flex items-center justify-center rounded-full py-2 px-4 mx-4 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-blue-600 text-white hover:text-slate-100 hover:bg-blue-500 active:bg-blue-800 active:text-blue-100 focus-visible:outline-blue-600 sm:hidden"
            >
              {option.name}
            </a>
          ) : (
            <a
              href={option.link}
              className="inline-block rounded-lg px-2 py-1 mx-4 text-sm text-slate-700 hover:bg-slate-100 hover:text-slate-900 sm:hidden"
            >
              {option.name}
            </a>
          );
        })}
        <div class="-mr-1 md:hidden">
          <div data-headlessui-state={mobileNavState ? "open" : ""}>
            <button
              class="relative z-10 flex h-8 w-8 items-center justify-center [:not(:focus-visible)]:focus:outline-none"
              aria-label="Toggle Navigation"
              id="headlessui-popover-button-:r0:"
              type="button"
              aria-expanded={mobileNavState ? true : false}
              data-headlessui-state={mobileNavState ? "open" : ""}
              onClick={() => handleToggle()}
            >
              <svg
                aria-hidden="true"
                class="h-3.5 w-3.5 overflow-visible stroke-slate-700"
                fill="none"
                stroke-width="2"
                stroke-linecap="round"
              >
                <path
                  d="M0 1H14M0 7H14M0 13H14"
                  class={
                    mobileNavState
                      ? "origin-center transition scale-90 opacity-0"
                      : "origin-center transition"
                  }
                ></path>
                <path
                  d="M2 2L12 12M12 2L2 12"
                  class={
                    mobileNavState
                      ? "origin-center transition"
                      : "origin-center transition scale-90 opacity-0"
                  }
                ></path>
              </svg>
            </button>
            {mobileNavState ? (
              <div>
                <div
                  class="fixed inset-0 bg-slate-300/50 opacity-100"
                  id="headlessui-popover-overlay-:r4a:"
                  aria-hidden="true"
                  data-headlessui-state="open"
                  onClick={() => handleToggle()}
                ></div>
                <div
                  class="absolute inset-x-0 mt-4 flex origin-top flex-col rounded-2xl bg-white p-4 text-lg tracking-tight text-slate-900 shadow-xl ring-1 ring-slate-900/5 opacity-100 scale-100 z-10"
                  id="headlessui-popover-panel-:r1:"
                  tabIndex="-1"
                  data-headlessui-state="open"
                >
                  {navOptions.map((option) => {
                    return (
                      <a
                        class="block w-full p-2"
                        data-headlessui-state="open"
                        href={`${option.link}`}
                        onClick={() => handleToggle()}
                      >
                        {option.name}
                      </a>
                    );
                  })}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
