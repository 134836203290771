import React from 'react';
import { BsChevronCompactLeft, BsChevronCompactRight } from 'react-icons/bs'
import { RxDotFilled } from 'react-icons/rx'

import buildScreen from "../../images/screens/test-build.png"
import testScreen from "../../images/screens/test-run.png"
import resultScreen from "../../images/screens/test-result.png"



const Carousel = (props) => {
  const slides = [buildScreen, testScreen, resultScreen];

  const prevSlide = () => {
    const isFirstSlide = props.currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : props.currentIndex - 1;
    props.setCurrentIndex(newIndex);
  };

  const nextSlide = () => {
    console.log("props ", props.currentIndex);
    const isLastSlide = props.currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : props.currentIndex + 1;
    props.setCurrentIndex(newIndex);
  };

  const goToSlide = (slideIndex) => {
    props.setCurrentIndex(slideIndex);
  };

  return (
    <div className="max-w-[1400px] h-[780px] w-full m-auto py-16 px-4 relative group sm:hidden md:block">
      <div
        style={{
          backgroundImage: `url(${slides[props.currentIndex]})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
        }}
        className="w-full h-full rounded-2xl bg-center bg-contain duration-500"
      ></div>
      <div className="hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
        <BsChevronCompactLeft onClick={prevSlide} size={30} />
      </div>
      <div className="hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] right-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
        <BsChevronCompactRight onClick={nextSlide} size={30} />
      </div>
      <div className="flex top-4 justify-center py-2">
        {slides.map((slide, slideIndex) => (
          <div
            key={slideIndex}
            onClick={() => goToSlide(slideIndex)}
            className="text-2xl cursor-pointer"
          >
            <RxDotFilled />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Carousel;
