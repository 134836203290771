import React from 'react';
import background from "../images/background-benefits.png"


const About = () => {
    return (
        <section id="about" aria-label='Features of our app' class="relative overflow-hidden bg-blue-600 pt-20 pb-28 sm:py-32">
            <img alt="" src={background} width="2347" height="1244" decoding="async" data-nimg="1" class="absolute top-1/2 left-1/2 max-w-none -translate-x-1/2 -translate-y-1/2" loading="lazy" style={{ color: "transparent" }}></img>
            <div class="max-w-4xl mx-auto px-4 text-left w-11/12" style={{ position: "inherit" }}>
                <h1 class="font-display text-3xl tracking-tight text-white sm:text-4xl md:text-5xl">We've gone through the process and know it can be better.</h1>
                <p class="mt-6 text-lg tracking-tight text-white">We are successfully certified across various levels of AWS certification ranging from associate to professional.</p>
                <div class="max-w-4xl mx-auto pt-6">
                    <p class="text-lg leading-relaxed mb-4 text-white">
                        We have been working in tech for the last 9 years and specifically with AWS for the last 5. We are AWS evangelists and understand the amazing capabilities of the cloud.
                    </p>
                    <p class="text-lg leading-relaxed mb-4 text-white">
                        We've used products like ACloudGuru, AWS white papers, and practice exams. We have found that practice exams were by far the best material prepare for the exams, however, the sites that host them (Whizlabs and Udemy) had great content but lacked the flexibility to optimize preparation.
                    </p>
                </div>
            </div>
        </section>
    );
}

export default About;
