import React, { useState } from "react"
import { CheckIcon, XCircleIcon } from "@heroicons/react/20/solid";
import { SiMicrosoftazure, SiGooglecloud, SiTerraform, SiAmazonaws } from "react-icons/si";


const examDetails =  
      [{
        AWS: [
          {
            examName: "AWS Certified Cloud Practitioner",
            examCode: "CCP",
            declarative: "Yes",
            generative: "Yes",
          },
          {
            examName: "AWS Certified Solutions Architect – Associate",
            examCode: "SAA-C03",
            declarative: "No",
            generative: "No",
          },
          {
            examName: "AWS Certified Developer – Associate",
            examCode: "DVA-C02",
            declarative: "No",
            generative: "No",
          },
          {
            examName: "AWS Certified Solutions Architect – Professional",
            examCode: "SAP-C02",
            declarative: "No",
            generative: "No",
          },
        ],
      },
      {
        Azure: [
            {
              examName: "Microsoft Azure Fundamentals",
              examCode: "AZ-900",
              declarative: "No",
              generative: "No",
            },
        ]
      },
      {
        GCP: [
            {
              examName: "Cloud Digital Leader",
              examCode: "CDL",
              declarative: "No",
              generative: "No",
            },
        ]
      },
      {
        Terraform: [
            {
              examName: "Terraform Associate",
              examCode: "TA-003",
              declarative: "No",
              generative: "No",
            },
        ]
    }]

const TestsAvailable = () => {
  const [selectedTab, setSelectedTab] = useState("AWS");

  return (
    <section
      id="tests-available"
      aria-label="Tests available on the platform"
      className="flex items-center justify-center sm:inline pt-12"
    >
      <div className="max-w-4xl px-4 py-4 m-auto">
        <div className="my-6 max-w-full p-6 bg-white border border-gray-200 rounded-lg shadow">
          <h1 class="mb-2 text-2xl font-display text-gray-900">
            Available Exams
          </h1>

          <div className="border-b border-gray-200">
            <ul className="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500">
              <li className="me-2">
                <button
                  onClick={() => setSelectedTab("AWS")}
                  className={`inline-flex items-center justify-center m-2 p-4 border-b-2 rounded-t-lg group ${
                    selectedTab === "AWS" ? "text-blue-600 border-blue-600" : ""
                  }`}
                >
                  <SiAmazonaws
                    className={`w-4 h-4 mx-2 ${
                      selectedTab === "AWS"
                        ? "text-blue-600"
                        : "text-gray-400 group-hover:text-gray-500 "
                    }`}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                  />
                  <span className="hidden sm:inline">AWS</span>
                  <span className="sm:hidden">Amazon Web Services</span>{" "}
                </button>
              </li>
              <li class="me-2">
                <button
                  onClick={() => setSelectedTab("Azure")}
                  className={`inline-flex items-center justify-center m-2 p-4 border-b-2 rounded-t-lg group ${
                    selectedTab === "Azure"
                      ? "text-blue-600 border-blue-600"
                      : ""
                  }`}
                  aria-current="page"
                >
                  <SiMicrosoftazure
                    className={`w-4 h-4 mx-2 ${
                      selectedTab === "Azure"
                        ? "text-blue-600"
                        : "text-gray-400 group-hover:text-gray-500"
                    }`}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                  />
                  Azure
                </button>
              </li>
              <li class="me-2">
                <button
                  onClick={() => setSelectedTab("GCP")}
                  className={`inline-flex items-center justify-center m-2 p-4 border-b-2 rounded-t-lg group ${
                    selectedTab === "GCP" ? "text-blue-600 border-blue-600" : ""
                  }`}
                >
                  <SiGooglecloud
                    className={`w-4 h-4 mx-2 ${
                      selectedTab === "GCP"
                        ? "text-blue-600"
                        : "text-gray-400 group-hover:text-gray-500"
                    }`}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                  />
                  <span className="hidden sm:inline">GCP</span>
                  <span className="sm:hidden">Google Cloud</span>{" "}
                </button>
              </li>
              <li class="me-2">
                <button
                  onClick={() => setSelectedTab("Terraform")}
                  class={`inline-flex items-center justify-center m-2 p-4 border-b-2 rounded-t-lg group ${
                    selectedTab === "Terraform"
                      ? "text-blue-600 border-blue-600"
                      : ""
                  }`}
                >
                  <SiTerraform
                    className={`w-4 h-4 mx-2 ${
                      selectedTab === "Terraform"
                        ? "text-blue-600"
                        : "text-gray-400 group-hover:text-gray-500"
                    }`}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                  />
                  Terraform
                </button>
              </li>
            </ul>
          </div>
          {selectedTab === "AWS" && (
            <div>
              <ExamDetails selectedTab={selectedTab} />
            </div>
          )}
          {selectedTab === "Azure" && (
            <div>
              <ExamDetails selectedTab={selectedTab} />
            </div>
          )}
          {selectedTab === "GCP" && (
            <div>
              <ExamDetails selectedTab={selectedTab} />
            </div>
          )}
          {selectedTab === "Terraform" && (
            <div>
              <ExamDetails selectedTab={selectedTab} />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

const ExamDetails = (props) => {
  return (
    <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
      <table class="w-full text-sm text-left rtl:text-right text-gray-500">
        <thead class="text-xs text-gray-700 uppercase bg-gray-50">
          <tr>
            <th scope="col" class="px-6 py-3">
              Exam Name
            </th>
            <th scope="col" class="px-6 py-3">
              Exam Code
            </th>
            <th scope="col" class="px-6 py-3">
              Declarative
            </th>
            <th scope="col" class="px-6 py-3">
              Generative
            </th>
          </tr>
        </thead>
        <tbody>
          {props.selectedTab &&
            examDetails
              .find((detail) => detail[props.selectedTab])
              [props.selectedTab].map((exam, index) => (
                <tr
                  key={index}
                  className="odd:bg-white even:bg-gray-50"
                >
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                  >
                    {exam.examName}
                  </th>
                  <td className="px-6 py-4">{exam.examCode}</td>
                  <td className="px-6 py-4 flex">
                    {exam.declarative === "Yes" ? (
                      <CheckIcon
                        className="h-6 w-5 text-blue-600 mx-auto"
                        aria-hidden="true"
                      />
                    ) : (
                      <XCircleIcon
                        className="h-6 w-5 text-blue-600 mx-auto"
                        aria-hidden="true"
                      />
                    )}
                  </td>
                  <td className="px-6 py-4">
                    {exam.generative === "Yes" ? (
                      <CheckIcon
                        className="h-6 w-5 text-blue-600 mx-auto"
                        aria-hidden="true"
                      />
                    ) : (
                      <XCircleIcon
                        className="h-6 w-5 text-blue-600 mx-auto"
                        aria-hidden="true"
                      />
                    )}
                  </td>
                </tr>
              ))}
        </tbody>
      </table>
    </div>
  );
};

export default TestsAvailable
