import React from 'react';
import background from "../images/background-benefits.png"
import llmImage from "../images/illustrations/llm-description-img.png"


const Generative = () => {
    return (
        <section id="about" aria-label='Features of our app' class="relative overflow-hidden bg-blue-600 pt-20 pb-28 sm:py-32">
            <img alt="" src={background} width="2347" height="1244" decoding="async" data-nimg="1" class="absolute top-1/2 left-1/2 max-w-none -translate-x-1/2 -translate-y-1/2" loading="lazy" style={{ color: "transparent" }}></img>
            <div class="max-w-4xl mx-auto px-4 text-left w-11/12" style={{ position: "inherit" }}>
                <h1 class="font-display text-center text-3xl tracking-tight text-white sm:text-4xl md:text-5xl">Generative Questions</h1>
                <p className="text-center mt-6 sm:px-4 text-lg leading-8 text-white">
                    Powered by LLMs, generative questions are created using the retrieval augment generate (RAG) technique to provide users with questions based on source materials and white papers provided directly from the source.
                </p>
                <img src={llmImage} alt="llm" className="mt-6 sm:px-4 w-full" />
                <p className="text-center mt-6 sm:px-4 text-lg leading-8 text-white">
                    Cloud certification prep consumes thousands of the latest white papers and documents from certification providers to generate unique and relevant questions for your preparation experience.
                </p>
            </div>
        </section>
    );
}

export default Generative;
