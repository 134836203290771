import React, { useState } from 'react';

import { AcademicCapIcon, WrenchIcon, ChartBarIcon} from '@heroicons/react/24/outline'

import Carousel from './global/Slider';

import buildScreen from "../images/screens/test-build.png"
import testScreen from "../images/screens/test-run.png"
import resultScreen from "../images/screens/test-result.png"


const BenefitsSection = () => {

    const [currentIndex, setCurrentIndex] = useState(0)


    const handleSelection = (slideIndex) => {
        setCurrentIndex(slideIndex)
    }

    return (
        <section id="features" aria-label='Features of our app' class="relative overflow-hidden bg-white-100 pt-20 pb-28 sm:py-18">
            <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8" style={{ position: "inherit" }}>
                <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                    <div class="mx-auto max-w-2xl md:text-center">
                        <h2 className={`font-display text-3xl tracking-tight sm:text-4xl md:text-5xl text-gray-800`}>Simplify your test preparation.</h2>
                        <p class="mt-4 text-xl tracking-tight text-gray-700 pt-12">After all, our goal is to make your test prep straightforward, not more complicated.</p>
                    </div>
                </div>
                <div class="grid md:grid-cols-3 sm:grid-rows-3 md:gap-x-8 sm:gap-y-8 md:gap-y-8 pt-12" role="tablist" aria-orientation="horizontal">
                    <div class="relative opacity-90 hover:opacity-100 text-left sm:pt-12 md:pt-2" onClick={() => handleSelection(0)}>
                        <div class="w-9 rounded-lg">
                            <WrenchIcon
                                className={`h-6 w-6 ${currentIndex === 0 ? "text-blue-600" : "text-black"}`}
                                aria-hidden="true"
                            />
                        </div>
                        <h3 class={`mt-6 text-sm font-medium ${currentIndex === 0 ? "text-blue-600" : "text-gray-700"}`}>
                            <button class="[&:not(:focus-visible)]:focus:outline-none" id="headlessui-tabs-tab-:rd:" role="tab" type="button" aria-selected="true" tabindex="0" data-headlessui-state="selected" aria-controls="headlessui-tabs-panel-:rg:">
                                <span class="absolute inset-0"></span>
                                Build
                            </button>
                        </h3>
                        <p class={`mt-2 font-display text-2xl ${currentIndex === 0 ? "text-blue-600" : "text-slate-900"}`}>Build the practice tests that are tailored to your needs.</p>
                        <p class={`mt-4 text-md ${currentIndex === 0 ? "text-blue-600" : "text-gray-700"}`}>Adjust the length, topics, and question types to provide a tailored practice exam for your needs. That first practice test can be really challenging and often times disheartening when you get a low score. By adding different layers of customization preparation becomes a gradual process with a more linear learning curve.</p>
                        <div class="w9 rounded-lg pt-4">
                            <img alt="build test screenshot" src={buildScreen} class="w-full rounded-2xl bg-center bg-contain sm:visible md:hidden" />
                        </div>
                    </div>
                    <div class="relative opacity-90 hover:opacity-100 text-left" onClick={() => handleSelection(1)}>
                        <div class="w-9 rounded-lg">
                            <AcademicCapIcon
                                className={`h-6 w-6 ${currentIndex === 1 ? "text-blue-600" : "text-black"}`}
                                aria-hidden="true"
                            />
                        </div>
                        <h3 class={`mt-6 text-sm font-medium ${currentIndex === 1 ? "text-blue-600" : "text-gray-700"}`}>
                            <button class="[&:not(:focus-visible)]:focus:outline-none" id="headlessui-tabs-tab-:rd:" role="tab" type="button" aria-selected="true" tabindex="0" data-headlessui-state="selected" aria-controls="headlessui-tabs-panel-:rg:">
                                <span class="absolute inset-0"></span>
                                Execute
                            </button>
                        </h3>
                        <p class={`mt-2 font-display text-2xl ${currentIndex === 1 ? "text-blue-600" : "text-slate-900"}`}>Attempt the practice exams in a contained environment.</p>
                        <p class={`mt-4 text-md ${currentIndex === 1 ? "text-blue-600" : "text-gray-700"}`}>Work through exams at your own pace. Simulate real exam scenarios or set up a quick set of questions on a specific topic. You control your test environments. Results are saved and can be referenced or retaken later. The current practice test ecosystem lacks structure and flexibility. Cloud Certification Prep lets you build tailored exams for you.</p>
                        <div class="w9 rounded-lg pt-4">
                            <img alt="test execution screenshot" src={testScreen} class="w-full rounded-2xl bg-center bg-contain sm:visible md:hidden" />
                        </div>
                    </div>
                    <div class="relative opacity-90 hover:opacity-100 text-left" onClick={() => handleSelection(2)}>
                        <div class="w-9 rounded-lg">
                            <ChartBarIcon
                                className={`h-6 w-6 ${currentIndex === 2 ? "text-blue-600" : "text-black"}`}
                                aria-hidden="true"
                            />
                        </div>
                        <h3 class={`mt-6 text-sm font-medium ${currentIndex === 2 ? "text-blue-600" : "text-gray-700"}`}>
                            <button class="[&:not(:focus-visible)]:focus:outline-none" id="headlessui-tabs-tab-:rd:" role="tab" type="button" aria-selected="true" tabindex="0" data-headlessui-state="selected" aria-controls="headlessui-tabs-panel-:rg:">
                                <span class="absolute inset-0"></span>
                                Optimize
                            </button>
                        </h3>
                        <p class={`mt-2 font-display text-2xl ${currentIndex === 2 ? "text-blue-600/100" : "text-slate-900"}`}>Dynamically adjust your studying based on feedback and performance.</p>
                        <p class={`mt-4 text-md ${currentIndex === 2 ? "text-blue-600/100" : "text-gray-700"}`}>Know exactly how you're doing and get a recommendation of when you are ready to take the exam. This is typically hard to judge, practice exams are hard and getting a passing score isn't always an indication that you are ready. By tracking your results against your custom test types we can determine where you need more study time and when you are ready to sit the exam.</p>
                        <div class="w9 rounded-lg pt-4">
                            <img alt= "result screenshot" src={resultScreen} class="w-full rounded-2xl bg-center bg-contain sm:visible md:hidden" />
                        </div>
                    </div>
                </div>
                <Carousel
                    currentIndex={currentIndex}
                    setCurrentIndex={setCurrentIndex}
                />
            </div>
        </section>
    );
};

export default BenefitsSection;