import React from "react";

import saAssociateLogo from "../images/logos/sa-associate.png";
import saProfessionalLogo from "../images/logos/sa-professional.png";
import terraformAssociateLogo from "../images/logos/terraform-associate.png";

import { PlayIcon } from "@heroicons/react/20/solid";

const MainHero = () => {
  return (
    <div class="mx-auto max-w-7xl px-4 md:px-6 lg:px-8 pt-20 pb-16 text-center lg:pt-20">
      <h1 class="mx-auto max-w-4xl font-display text-5xl font-medium tracking-tight text-slate-900 md:text-7xl">
        Certification Preparation
        <span class="relative whitespace-nowrap text-blue-600">
          <svg
            aria-hidden="true"
            viewBox="0 0 418 42"
            class="sm:relative md:absolute top-2/3 left-0 h-[0.58em] w-full fill-blue-300/70"
            preserveAspectRatio="none"
          >
            <path d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z"></path>
          </svg>
          <span class="relative"> made simple</span>
        </span>{" "}
        for you.
      </h1>
      <p class="mx-auto pt-4 mt-6 max-w-2xl text-xl tracking-tight text-slate-700">
        Enhance Your IT Certification Journey: A tailored platform enabling you
        to construct custom tests, fine-tune difficulty levels, and monitor your
        progress, boosting your likelihood of certification success.
      </p>
      <div class="mt-10 flex-col justify-center gap-x-6">
        <p className="font-display text-xl text-slate-900 sm:pt-8 mb-4">
          Get started today!
        </p>
        <a
          class="group inline-flex mx-2 ring-1 items-center justify-center rounded-full py-2 px-4 text-sm focus:outline-none ring-slate-200 text-slate-700 hover:text-slate-900 hover:ring-slate-300 active:bg-slate-100 active:text-slate-600 focus-visible:outline-blue-600 focus-visible:ring-slate-300"
          href="https://www.youtube.com/embed/BME8f0lvvSY"
        >
          <PlayIcon
            aria-hidden="true"
            className="h-3 w-3 flex-none fill-blue-600 group-active:fill-current"
          />
          <span className="ml-3">Watch video</span>
        </a>
      </div>
      <div class="lg:mt-10 md:mt-4">
        <p class="font-display text-lg text-slate-900 sm:pt-8">
          Built by certified IT professionals
        </p>
        <ul class="mt-8 flex items-center justify-center gap-x-8 md:flex-col md:gap-x-0 md:gap-y-10 xl:flex-row xl:gap-x-12 xl:gap-y-0">
          <li>
            <ul class="flex flex-col items-center gap-y-8 md:flex-row md:gap-x-12 md:gap-y-0">
              <li class="flex">
                <img
                  alt="Tuple"
                  src={saAssociateLogo}
                  width="105"
                  height="48"
                  decoding="async"
                  data-nimg="1"
                  loading="lazy"
                  style={{ color: "transparent;" }}
                />
              </li>
              <li class="flex">
                <img
                  alt="Tuple"
                  src={saProfessionalLogo}
                  width="105"
                  height="48"
                  decoding="async"
                  data-nimg="1"
                  loading="lazy"
                  style={{ color: "transparent;" }}
                />
              </li>
              <li class="flex">
                <img
                  alt="Tuple"
                  src={terraformAssociateLogo}
                  width="105"
                  height="48"
                  decoding="async"
                  data-nimg="1"
                  loading="lazy"
                  style={{ color: "transparent;" }}
                />
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default MainHero;
