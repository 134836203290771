import { CheckIcon, XCircleIcon } from "@heroicons/react/20/solid";

const plans = [
  {
    name: "Basic",
    price: 12.99,
    description:
      "The basic plan provides access to the platform with unlimited test creation (declarative of generative questions) and course selections. Users also get access to the exam dashboard which monitors your performance over time.",
    features: [
      { feature: "Unlimited access to all courses", plan: true },
      { feature: "Dashboard for tracking your progress", plan: true },
      { feature: "Access to declarative and generative questions", plan: true },
      { feature: "LLM driven insights for specific exam results", plan: false },
      { feature: "Overall exam readiness score", plan: false },
    ],
  },
  {
    name: "Generative",
    price: 19.99,
    description:
      "The generative plan provides access to insights, a feature that helps guide the test taker through their certification journey with recommendations after each test and an overall readniess score based on your performance.",
    features: [
      { feature: "Unlimited access to all courses", plan: true },
      { feature: "Dashboard for tracking your progress", plan: true },
      { feature: "Access to declarative and generative questions", plan: true },
      { feature: "LLM driven insights for specific exam results", plan: true },
      { feature: "Overall exam readiness score", plan: true },
    ],
  },
];

export default function Pricing() {
  return (
    <section
      id="pricing"
      aria-label="Pricing for the platform"
    >
      <div className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-2xl sm:text-center">
          <h2 className="text-5xl font-display tracking-tight text-gray-900 sm:text-4xl">
            Simple no-tricks monthly pricing
          </h2>
          <p className="mt-6 sm:px-4 text-lg leading-8 text-gray-600">
            Two plans are available currently for purchase. As we build we are
            offering free monthly memberships for the first 25 users who sign up
            in the form below. You'll know you have access if you are directed
            to the sign-up form versus a payment form.
          </p>
        </div>
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
            {plans.map((plan) => (
              <div className="p-8 sm:p-10 lg:flex-auto">
                <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
                  <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
                    <div className="mx-auto max-w-xs px-8">
                      <h3 className="text-2xl font-bold tracking-tight text-gray-900">
                        {plan.name}
                      </h3>
                      <p className="mt-6 text-base leading-7 text-gray-600">
                        {plan.description}
                      </p>
                      <p className="mt-6 flex items-baseline justify-center gap-x-2">
                        <span className="text-5xl font-bold tracking-tight text-gray-900">
                          ${plan.price}
                        </span>
                        <span className="text-sm font-semibold leading-6 tracking-wide text-gray-600">
                          USD
                        </span>
                      </p>
                      <a
                        href="#contact"
                        className="mt-10 block w-full rounded-md bg-blue-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        Get access
                      </a>
                      <p className="mt-6 text-xs leading-5 text-gray-600">
                        Invoices and receipts available for easy company
                        reimbursement
                      </p>
                    </div>
                    <div className="mx-auto max-w-7xl px-6 lg:px-8">
                      <div className="mt-10 flex items-center gap-x-4">
                        <h4 className="flex-none text-sm font-semibold leading-6 text-blue-600">
                          What’s included
                        </h4>
                        <div className="h-px flex-auto bg-gray-100" />
                      </div>
                      <ul
                        role="list"
                        className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
                      >
                        {plan.features.map((item) => (
                          <li key={item} className="flex gap-x-3">
                            {item.plan ? (
                              <CheckIcon
                                className="h-6 w-5 flex-none text-blue-600"
                                aria-hidden="true"
                              />
                            ) : (
                              <XCircleIcon
                                className="h-6 w-5 flex-none text-blue-600"
                                aria-hidden="true"
                              />
                            )}
                            {item.feature}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
