import { useEffect } from "react"
import { Helmet } from "react-helmet"
import { Crisp } from "crisp-sdk-web"

import Navigation from "./components/Navigation"
import MainHero from "./components/MainHero"
import TestsAvailable from "./components/TestsAvailable"
import BenefitsSection from './components/Benefits';
import Generative from "./components/Generative";
import Pricing from "./components/Pricing"
import About from './components/About';
import ContactUsSection from './components/Contact';
import Footer from "./components/Footer"

import ReactGA from 'react-ga4'

const TRACKING_ID = "G-06ST5FMRHV"
ReactGA.initialize(TRACKING_ID)

function App() {

  useEffect(() => {
    ReactGA.event('page_view', {
      page_location: window.location.href,
      page_path: window.location.pathname,
      send_to: TRACKING_ID
    });
  },[])

  Crisp.configure("276c9fdc-9443-49a1-a6e7-4a6f0c585c3f")

  return (
    <div class="App">
        <Helmet>
          <title>Cloud Certification Prep | Home</title>
          <meta name="description" content="Prepare for your AWS certification with our platform. Simplify your test preparation through LLMs and automation." />
        </Helmet>
        <Navigation />
        <MainHero />
        <TestsAvailable />
        <About />
        <BenefitsSection />
        <Generative />
        <Pricing />
        <ContactUsSection />
        <Footer />
    </div>
  );
}

export default App;
