let url = "https://2993lyfq55.execute-api.ca-central-1.amazonaws.com/test/"


let addEmail = (email) => {
    fetch(url, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({email:email})
    })
}

exports.addEmail = addEmail

