import React, {useState } from 'react';
import background from "../images/background-benefits.png"

import { addEmail } from "../clientAPI/server"

import contactUsInitialImage from "../images/illustrations/contact-us-initial-img.png"
import contactUsSentImage from "../images/illustrations/contact-us-email-sent.png"

const ContactUsSection = () => {

    const [emailFieldValue, setEmailFieldValue] = useState("")
    const [emailSubmittedStatus, setEmailSubmittedStatus] = useState(false)
    const [validEmailStatus, setValidEmailStatus] = useState(true)


    const handleEmailEntry = (event) => {
        setEmailFieldValue(event.target.value)
    }

    const handleEmailSubmission = () => {
        if(emailFieldValue.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)){
            setValidEmailStatus(true)
            setEmailSubmittedStatus(true)
            addEmail(emailFieldValue)
        } else {
            setValidEmailStatus(false)
            setEmailFieldValue("")
        }
    }

    return (
        <section id="contact" aria-label='Features of our app' class="relative overflow-hidden bg-blue-600 pt-20 pb-28 sm:py-24">
        <img alt="" src={background} width="2347" height="1244" decoding="async" data-nimg="1" class="absolute top-1/2 left-1/2 max-w-none translate-x-[-44%] translate-y-[-42%]" loading="lazy" style={{ color: "transparent" }}></img>
            <div class="container mx-auto w-11/12" style={{ position: "inherit" }}>
                <h2 class="text-3xl font-bold mb-6 text-center text-white">Contact Us</h2>
                <p class="text-xl text-white leading-relaxed mb-4 text-center">
                    If you are interested in getting access to the full platform register below!
                </p>
                <div class="flex flex-wrap justify-center pt-12">
                    <div class="w-full lg:w-1/2 mb-6 lg:mb-0">
                        <form class="bg-white shadow-md rounded-lg px-8 pt-12 pb-8 mb-4">
                            <div class="mb-4">
                                {emailSubmittedStatus && validEmailStatus ? 
                                    <div class="w-auto">
                                        <img alt="Sent email illustration" src={contactUsSentImage} class="w-1/2 h-auto rounded-lg mx-auto" />
                                        <label class="block text-gray-700 text-md mb-2 pb-6 text-left pt-6 text-center" for="email">
                                            Thanks for submitting your email, we'll be in touch!.
                                        </label>
                                    </div>
                                    :
                                    <div class="w-auto">
                                        <img alt="Sent email illustration" src={contactUsInitialImage} class="w-1/2 h-auto rounded-lg mx-auto" />
                                        <label class="block text-gray-700 text-md mb-2 pb-6 text-left pt-6 text-center" for="email">
                                            Send us your email if you're interested in learning more or getting access to the platform.
                                        </label>
                                    </div>
                                }
                                {!validEmailStatus ?
                                    <label class="block text-red-600 text-sm mb-2 pb-6 text-left" for="email">
                                        Please submit a valid email.
                                    </label>
                                    :
                                    <></>
                                }
                                <input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="email" type="email" onChange={handleEmailEntry} value={emailFieldValue} placeholder="Email" />
                            </div>
                            <div class="flex items-center justify-center">
                                {emailSubmittedStatus || !validEmailStatus && emailFieldValue === "" ?
                                    <button class="bg-blue-300 hover:bg-blue-300 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" disabled type="button" onClick={handleEmailSubmission}>{!validEmailStatus ? "Sign-up" : "Submitted"}</button>
                                    :
                                    <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button" onClick={handleEmailSubmission}>Sign-up</button>
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ContactUsSection;
